import * as React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
    <Layout>
        <section>
            <div className="error-page">
                <h1>404: Not Found</h1>
                <p>
                    You just hit a route that doesn&#39;t exist... the sadness.
                </p>
            </div>
        </section>
    </Layout>
);

export default NotFoundPage;
